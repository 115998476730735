















































































import {
  defineComponent, reactive, computed, watch, watchEffect,
} from '@vue/composition-api';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import { $DYNAMIC_REPORT_CONFIGS } from '../composables/config';
import { formatData } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;
    const statementData = apiData.balance_sheet;
    const seriesKeys = reactive([]);
    const fieldKeys = reactive([]);
    const dataSet = reactive({});
    const configs = computed(
      () => $DYNAMIC_REPORT_CONFIGS.value.find(item => item.name === 'balance_sheet'),
    );
    const reverseSpectrum = [
      'accounts_payable',
      'other_current_liability',
      'current_liability',
      'long_term_loan',
      'other_non_current_liability',
      'non_current_liability',
      'total_liability',
    ];

    watchEffect(
      () => {
        const rawSeriesKeys = configs.value.series.filter(item => item.display).map(item => item.name);
        const anualReportKeys = Object.keys(statementData).filter(item => item.match(/^\d+$/));
        rawSeriesKeys.splice(rawSeriesKeys.indexOf('anual_report'), 1, ...anualReportKeys);
        seriesKeys.splice(0, seriesKeys.length, ...rawSeriesKeys);
        fieldKeys.splice(0, fieldKeys.length, ...configs.value.fields.filter(item => item.display).map(item => item.name));

        seriesKeys.forEach((series, seriesIndex) => {
          const formattedData = {};
          fieldKeys.forEach((field, fieldIndex) => {
            const format = series === 'percentile' ? null : '0,0.00';

            Object.assign(formattedData, {
              [field]: formatData(statementData[series][field], format),
            });
          });

          Object.assign(dataSet, {
            [series]: formattedData,
          });
        });
      },
    );

    /** Methods */
    const fieldConfigs = (fieldName: string) => configs.value.fields.find(item => item.name === fieldName);

    return {
      title: 'งบดุล (Balance Sheet)',
      dataSet,
      reverseSpectrum,
      configs,
      seriesKeys,
      fieldKeys,
      fieldConfigs,
    };
  },
});
